export default function signinRedirectHandler(BASE: string) {
  let NEW_BASE = "";

  if (BASE === "https://api.onefinnet.com") {
    NEW_BASE = "https://auth.onefinnet.com/signin";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://auth.test.ecndev.io/signin";
  } else {
    NEW_BASE = "https://auth.dev.ecndev.io/signin";
  }
  return NEW_BASE;
}

export function signupRedirectHandler(BASE: string) {
  let NEW_BASE = "";

  if (BASE === "https://api.onefinnet.com") {
    NEW_BASE = "https://auth.onefinnet.com/signup";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://auth.test.ecndev.io/signup";
  } else {
    NEW_BASE = "https://auth.dev.ecndev.io/signup";
  }
  return NEW_BASE;
}

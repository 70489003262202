import { CircularProgress } from "@mui/material";

const Loader = (props: any) => {
    return (
        <div className="loader" >
            <CircularProgress style={props.style} />
        </div>
    );
};

export default Loader;

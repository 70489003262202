import React from 'react'
import Lottie from 'react-lottie';
import NotFound404 from '../assets/lotties/NotFound404.json';

import { Icon } from '@iconify/react';
import Header from '../components/Nav/Header';

import { useNavigate } from 'react-router-dom';

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <div className='not-found'>
            <Header />
            <div className="main-group-404">
                <div className="doesnt-exist">
                    This URL Doesn't exist!
                </div>
                <div className="gif">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: NotFound404,
                        }}
                    />
                </div>
                <div
                    className="redirect "
                    onClick={() => navigate('/')}
                >
                    <div className="contained">

                        <span data-hover="Visit my portal">
                            <div>Redirect to Home</div>
                            <Icon icon="teenyicons:arrow-right-solid"
                                width={window.innerWidth < 768 ? 14 : 21}
                                height={window.innerWidth < 768 ? 14 : 21}
                            />
                        </span>
                        <span data-hover="Visit my portal">
                            <div>Redirect to Home</div>
                            <Icon icon="teenyicons:arrow-right-solid"
                                width={window.innerWidth < 768 ? 14 : 21}
                                height={window.innerWidth < 768 ? 14 : 21}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound

import getCompanyDetailSlice from "./getCompanyDetailSlice";
import getCompanyJobsSlice from "./getCompanyJobsSlice";
import getJobDetailSlice from "./getJobDetailSlice";
import getLocationSlice from "./getLocationSlice";
import getUniAndClubSlice from "./getUniAndClubSlice";
import postJobApplySlice from "./postJobApplySlice";
import postUserResumeSlice from "./postUserResumeSlice";

import checkPrivateJobUrlSlice from "./checkPrivateJobBoardUrl";

import getStorageTokenSlice from "./getStorageTokenSlice";


export const allJobSlices = {
  getCompanyDetails: getCompanyDetailSlice,
  getCompanyJobs: getCompanyJobsSlice,
  getJobDetails: getJobDetailSlice,
  postUserResume: postUserResumeSlice,
  getStorageToken: getStorageTokenSlice,
  getUniAndClub: getUniAndClubSlice,
  getLocations: getLocationSlice,
  postJobApply: postJobApplySlice,
  checkPrivateJobBoardUrl: checkPrivateJobUrlSlice,
};

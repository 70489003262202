import React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";

const EcnCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      {...props}
      className={
        props.className ? props.className + " ecn-checkbox" : "ecn-checkbox"
      }
    />
  );
};

export default EcnCheckbox;

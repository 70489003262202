import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const CORE_BASE = process.env.REACT_APP_CORE_BASE || "";

export const getUniAndClub = createAsyncThunk("getUniAndClub", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + CORE_BASE}/api/v1/block/628b73b0e50df672d92f3fca`
    );

    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data.statusCode;
  }
});

const getUniAndClubSlice = createSlice({
  name: "getUniAndClub",
  initialState: {
    loading: false,
    uniNClub: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUniAndClub.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUniAndClub.fulfilled, (state, action) => {
      state.loading = false;
      state.uniNClub = action.payload;
    });
    builder.addCase(getUniAndClub.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getUniAndClubSlice.reducer;

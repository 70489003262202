import React from "react";
import EcnInput from "../../StyledComponents/EcnInput";
import EcnButton from "../../StyledComponents/EcnButton";
import { Autocomplete } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";

interface EducationGroupProps {
  edu: any;
  index: number;
  onChange: (index: number, field: string, value: any) => void;
  onRemove: (index: number) => void;
  isLast: boolean;
}

const EducationGroup = ({
  edu,
  index,
  onChange,
  onRemove,
  isLast,
}: EducationGroupProps) => {
  const { uniNClub } = useAppSelector((state: any) => state.getUniAndClub);
  return (
    <div className="education-group">
      <div className="row">
        <div className="field">
          <label htmlFor="">Education Level*</label>
          <Autocomplete
            sx={{ width: "100%" }}
            disableClearable
            size="small"
            options={eduLevel}
            defaultValue={edu?.education_type === 1 ? "MBA" : "Undergrad"}
            onChange={(e, value) => onChange(index, "education_type", value)}
            renderInput={(params) => (
              <EcnInput {...params} className="ecn-input" required />
            )}
          />
        </div>

        <div className="field">
          <label htmlFor="">University*</label>
          <Autocomplete
            disableClearable
            freeSolo
            autoSelect
            size="small"
            value={edu ? edu.name : ""}
            options={
              uniNClub?.records.length > 0 && uniNClub?.records[0]?.record
                ? edu.education_type === 1
                  ? uniNClub?.records[0]?.record?.university_mba.map(
                      (item: any) => item["Name"]
                    )
                  : uniNClub?.records[0]?.record?.university_ug.map(
                      (item: any) => item["Name"]
                    )
                : []
            }
            getOptionLabel={(uni) => uni}
            onChange={(e, value) => onChange(index, "name", value)}
            renderInput={(params) => (
              <EcnInput
                {...params}
                placeholder="Type in University if not on List"
                className="ecn-input"
                size="small"
              />
            )}
          />
        </div>

        <div className="field">
          <label htmlFor="">Graduation Year</label>
          <Autocomplete
            disableClearable
            freeSolo
            autoSelect
            size="small"
            className="year-select"
            options={gradYear}
            value={edu ? (edu.graduation_year !== 0 ? edu.graduation_year : "") : ""}
            onChange={(e, value) => onChange(index, "graduation_year", value)}
            renderInput={(params) => (
              <EcnInput {...params} className="ecn-input" size="small" />
            )}
          />
        </div>
      </div>

      {!isLast && (
        <div className="remove-wrapper">
          <EcnButton type="button" color="secondary" onClick={() => onRemove(index)}>
            Remove
          </EcnButton>
        </div>
      )}
    </div>
  );
};

export default EducationGroup;

const gradYear = [
  "2029",
  "2028",
  "2027",
  "2026",
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
];

const eduLevel = ["Undergrad", "MBA"];

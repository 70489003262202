import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const CORE_BASE = process.env.REACT_APP_CORE_BASE || "";

export const getLocation = createAsyncThunk(
  "getLocation",
  async (searchData: string) => {
    try {
      const { data } = await axios.get(
        `https://atlas.microsoft.com/search/address/json?api-version=1.0&entityType=Municipality&query=${searchData}&subscription-key=cTbC9G2Tkk76cfNABIKl5rrAdh0K5_PP_1pmc1zCN3Y`
      );

      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data.statusCode;
    }
  }
);

const getLocationSlice = createSlice({
  name: "getLocation",
  initialState: {
    loading: false,
    locationList: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.locationList = action.payload;
    });
    builder.addCase(getLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getLocationSlice.reducer;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userDetails } from "../../redux/slices/userSlices/userDetailsSlice";
import signinRedirectHandler, {
  signupRedirectHandler,
} from "../../helper/Checks/authRedirectHandlers";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const REDIRECTION_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://www.dev.ecndev.io"
    : "https://onefinnet.com";

const Header = () => {
  const dispatch = useAppDispatch();
  const { userInfo: user } = useAppSelector((state: any) => state.userDetails);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!user) {
      dispatch(userDetails());
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if page has scrolled beyond a certain threshold
      if (window.scrollY > 100) {
        // Adjust the threshold as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`header-v2 ${scrolled ? "reduced-header" : ""}`}
      id="header"
    >
      <div className="menu-list">
        <div className="menu">
          <div className="logo">
            <Link to="/">
              <img src="/b_elevate_2.png" alt="Elevate" />
            </Link>
          </div>

          <div className="design-buttons">
            {user ? (
              <>
                <button
                  className="contained"
                  onClick={() => {
                    window.location.href = `${REDIRECTION_BASE}/portal/events`;
                  }}
                >
                  <span data-hover="Visit my portal">Visit my portal</span>
                  <span data-hover="Visit my portal">Visit my portal</span>
                </button>
              </>
            ) : (
              <>
                <button
                  className="outlined"
                  onClick={() => {
                    window.location.href = `${signinRedirectHandler(REDIRECTION_BASE)}?origin=${window?.location.href
                      }`;
                  }}
                >
                  Log in
                </button>
                <button
                  className="contained"
                  onClick={() => {
                    window.location.href = `${signupRedirectHandler(REDIRECTION_BASE)}?origin=${window?.location.href
                      }`;
                  }}
                >
                  <span data-hover="Get Started">Get Started</span>
                  <span data-hover="Get Started">Get Started</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

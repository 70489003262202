import React, { useEffect, useState } from "react";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { getJobDetail } from "../redux/slices/jobSlices/getJobDetailSlice";
import moment from "moment";
import { Chip, Divider, IconButton } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ShareJobDialog from "../components/JobDetail/ShareJobDialog";
import NotFound from "./NotFound";
import Loader from "../components/UI/Loader";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";

const JobDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { jobId, companyName } = useParams<{
    jobId: string;
    companyName: string;
  }>();

  const { userInfo: user } = useAppSelector((state: any) => state.userDetails);

  const { jobData, loading } = useAppSelector((state) => state.getJobDetails);
  const [shareJobOpen, setShareJobOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      dispatch(userDetails());
    }
  }, [user]);

  useEffect(() => {
    if (jobId) dispatch(getJobDetail({ jobId: jobId }));
  }, [jobId]);

  const applyHandler = () => {
    navigate("apply");
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : jobData ? (
        <div className="job-detail">
          <div className="head">
            <EcnButton
              color="secondary"
              startIcon={<Icon icon="material-symbols:arrow-back" />}
              onClick={() => {
                navigate(`/${companyName}`);
              }}
            >
              Back
            </EcnButton>
            <div className="icon">
              <p className="talent">Talent</p>
              <div className="divider-vert" />
              <img src="/b_elevate_2.png" alt="Elevate 2.0 icon" />
            </div>
          </div>

          <div className="job-card">
            <div className="title">
              <div className="title-data">
                <img
                  className="firm-logo"
                  src={jobData?.display_picture}
                  alt=""
                />

                <div className="data">
                  <p className="company">{jobData?.organisations?.[0]}</p>
                  <p className="job-title">{jobData?.job_title}</p>
                  <div className="row">
                    <p>
                      <Icon icon="lucide:briefcase-business" />{" "}
                      {jobData?.role_type}
                    </p>
                    <p>
                      <Icon icon="lucide:map-pin" />{" "}
                      {jobData?.address?.cities?.[0]}
                    </p>
                  </div>
                </div>
              </div>

              <div className="btn-group">
                {jobData?.closed ? (
                  <EcnButton
                    variant="contained"
                    disabled
                    className="btn-contained apply-btn closed"
                  >
                    Closed
                  </EcnButton>
                ) :
                  (jobData?.application ? (
                    <EcnButton
                      variant="contained"
                      disabled
                      className="btn-contained apply-btn"
                      sx={{ cursor: "not-allowed" }}
                    >
                      Applied
                    </EcnButton>
                  ) : (
                    <EcnButton
                      variant="contained"
                      className="apply-btn"
                      onClick={applyHandler}
                    >
                      Apply
                    </EcnButton>
                  ))}
                <IconButton
                  color="secondary"
                  className="share-btn"
                  onClick={() => setShareJobOpen(true)}
                >
                  <Icon icon="lucide:share-2" />
                </IconButton>
                <ShareJobDialog
                  link={`https://jobs.onefinnet.com/${companyName}/job/${jobId}`}
                  open={shareJobOpen}
                  setOpen={setShareJobOpen}
                />
              </div>
            </div>
            <div className="meta-data">
              <div className="block">
                <p className="heading">Industry: </p>
                <p className="info">{jobData?.industries?.[0]}</p>
              </div>

              <div className="block">
                <p className="heading">Experience: </p>
                <p className="info">
                  {jobData?.experience_required +
                    " - " +
                    jobData?.max_experience_required}{" "}
                  years
                </p>
              </div>

              <div className="block">
                <p className="heading">Posted on: </p>
                <p className="info">
                  {moment.unix(jobData?.createdAt).format("LL")}
                </p>
              </div>
            </div>
            <div className="skills">
              <p className="skill-head">Skills</p>
              <div className="container">
                {jobData?.skills?.map((skill: string) => (
                  <Chip className="skill-chip" label={skill} />
                ))}
              </div>
            </div>

            <Divider />

            <div className="description">
              <div className="field">
                {jobData?.description && (
                  <>
                    <p className="desc-heading">Job Description</p>
                    <div
                      className="markdown-data"
                      dangerouslySetInnerHTML={{
                        __html: jobData?.description,
                      }}
                    />
                  </>
                )}
              </div>

              <div className="field">
                {jobData?.firm_overview && (
                  <>
                    <p className="desc-heading">About the company</p>
                    <ReactMarkdown
                      className="markdown-data"
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    >
                      {jobData?.firm_overview}
                    </ReactMarkdown>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mob-apply">
            <EcnButton
              variant="contained"
              className="apply-btn"
              onClick={applyHandler}
            >
              Apply
            </EcnButton>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default JobDetail;

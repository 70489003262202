import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getCompanyJobs = createAsyncThunk(
  "getCompanyJobs",
  async (pageData: any) => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_BASE}/api/v1/firm/jobs?skip=${pageData.skip}&limit=${
          pageData.limit
        }&firm=${pageData.firm}${
          pageData.q && pageData.q !== "" ? "&q=" + pageData.q : ""
        }${pageData.userId ? "&userid=" + pageData.userId : ""}`
      );

      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data.statusCode;
    }
  }
);

const getCompanyJobsSlice = createSlice({
  name: "getCompanyJobs",
  initialState: {
    loading: false,
    jobList: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyJobs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompanyJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.jobList = action.payload;
    });
    builder.addCase(getCompanyJobs.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getCompanyJobsSlice.reducer;

import React, { useState } from "react";
import EcnInput from "../../StyledComponents/EcnInput";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import preferences from "../../../helper/preferences.json";
import { getLocation } from "../../../redux/slices/jobSlices/getLocationSlice";
import EcnButton from "../../StyledComponents/EcnButton";

interface WorkGroupProps {
  work: any;
  index: number;
  noWorkExp: boolean;
  updateWorkExperience: (index: number, updatedWork: any) => void;
  removeWorkExperience: (index: number) => void;
  isLast: boolean;
}

const WorkGroup = ({
  work,
  index,
  noWorkExp,
  updateWorkExperience,
  removeWorkExperience,
  isLast,
}: WorkGroupProps) => {
  const dispatch = useAppDispatch();

  const { uniNClub } = useAppSelector((state: any) => state.getUniAndClub);
  const { locationList } = useAppSelector((state: any) => state.getLocations);

  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(
    work.experience_status === 3
  );

  const addressListHandler = (e: any) => {
    if (e !== null) dispatch(getLocation(e.target.value));
  };

  const handleChange = (field: string, value: any) => {
    if (field === "start_date" || field === "end_date")
      updateWorkExperience(index, {
        [field]: value ? value.toISOString() : "2030-10-05T14:48:00.000Z",
      });
    else if (field === "city") {
      updateWorkExperience(index, {
        city: `${value?.address?.municipality +
          " " +
          value?.address?.countrySubdivision +
          " " +
          value?.address?.country
          }`,
      });
    } else updateWorkExperience(index, { [field]: value });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCurrentlyWorking(isChecked);
    handleChange("experience_status", isChecked ? 3 : 0);
    if (isChecked) {
      handleChange("end_date", null);
    }
  };

  return (
    <div className="work-group">
      <div className="row">
        <div className="field">
          <label htmlFor="">Company Name*</label>
          <Autocomplete
            sx={{ width: "100%" }}
            disableClearable
            freeSolo
            autoSelect
            disabled={noWorkExp}
            options={
              uniNClub?.records.length > 0 && uniNClub?.records[0]?.record
                ? uniNClub?.records[0]?.record?.firms.map(
                  (item: any) => item["Name"]
                )
                : []
            }
            getOptionLabel={(org) => org}
            value={work?.company}
            onChange={(e, val) => handleChange("company", val)}
            renderInput={(params: any) => (
              <EcnInput
                {...params}
                placeholder="Company Name"
                className="ecn-input"
                size="small"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                {...props}
                sx={{
                  margin: "6px 10px",
                  marginBottom: "0px !important",
                  borderRadius: "8px !important",
                  padding: "8px 15px !important",
                  fontSize: "14px !important",

                  bgcolor: selected
                    ? "rgba(0, 52, 187, 0.05) !important"
                    : "transparent", // Apply the "info" color for selected options
                  "&:hover": {
                    bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                    color: "rgba(0, 52, 187, 1)",
                  },
                  color: work?.company === option ? "rgba(0, 52, 187, 1)" : "",
                }}
              >
                {option}
              </Box>
            )}
          />
        </div>

        <div className="field">
          <label htmlFor="">Job Function*</label>
          <Autocomplete
            size="small"
            forcePopupIcon={false}
            disableClearable
            disabled={noWorkExp}
            options={preferences.jobFunction}
            value={work.title}
            onChange={(e, val) => handleChange("title", val)}
            renderInput={(params: any) => (
              <EcnInput
                {...params}
                size="small"
                color="info"
                placeholder="Job Function"
                className="ecn-input"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                {...props}
                sx={{
                  margin: "6px 10px",
                  marginBottom: "0px !important",
                  borderRadius: "8px !important",
                  padding: "8px 15px !important",
                  fontSize: "14px !important",

                  bgcolor: selected
                    ? "rgba(0, 52, 187, 0.05) !important"
                    : "transparent", // Apply the "info" color for selected options
                  "&:hover": {
                    bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                    color: "rgba(0, 52, 187, 1)",
                  },
                  color: work?.title === option ? "rgba(0, 52, 187, 1)" : "",
                }}
              >
                {option}
              </Box>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="field">
          <label htmlFor="">Job Role*</label>
          <Autocomplete
            disableClearable
            size="small"
            forcePopupIcon={false}
            disabled={noWorkExp}
            options={preferences.jobRoles}
            value={work.position}
            onChange={(e, val) => handleChange("position", val)}
            renderInput={(params: any) => (
              <EcnInput
                {...params}
                size="small"
                color="info"
                placeholder="Job Role"
                className="ecn-input"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                {...props}
                sx={{
                  margin: "6px 10px",
                  marginBottom: "0px !important",
                  borderRadius: "8px !important",
                  padding: "8px 15px !important",
                  fontSize: "14px !important",

                  bgcolor: selected
                    ? "rgba(0, 52, 187, 0.05) !important"
                    : "transparent", // Apply the "info" color for selected options
                  "&:hover": {
                    bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                    color: "rgba(0, 52, 187, 1)",
                  },
                  color: work.position === option ? "rgba(0, 52, 187, 1)" : "",
                }}
              >
                {option}
              </Box>
            )}
          />
        </div>

        <div className="field">
          <label htmlFor="">Experience Type*</label>
          <Select
            required
            size="small"
            color="info"
            id="experience_type"
            className="ecn-select"
            disabled={noWorkExp}
            value={work?.experience_type}
            onChange={(e) => handleChange("experience_type", e.target.value)}
            //IconComponent={KeyboardArrowDown}
            renderValue={
              work?.experience_type !== 0
                ? undefined
                : () => (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(33, 33, 33, 0.4)",
                    }}
                  >
                    Select type
                  </span>
                )
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },

              PaperProps: {
                sx: {
                  background: "#fff",
                  maxHeight: 300,
                  maxWidth: "600px",
                  boxShadow: 1,
                  padding: "0px 10px",
                  marginTop: "3px",
                },
              },
            }}
          >
            <MenuItem
              value={1}
              style={{
                borderRadius: "8px",
                fontFamily: "Plus Jakarta Sans !important",
              }}
              sx={
                work?.experience_type === 1
                  ? {
                    background: "rgba(0, 52, 187, 0.08)!important",
                    borderRadius: "9px",
                    marginBottom: "5px",
                    fontSize: "14px !important",
                    fontWeight: "400",
                    color: "#0034bb !important",
                    padding: "8px 15px",
                  }
                  : {
                    background: "#fff !important",
                    marginBottom: "5px",
                    fontSize: "14px !important",
                    color: "rgba(33,33,33,.7)",
                    fontWeight: "400",
                    padding: "8px 15px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "9px",
                    },
                  }
              }
            >
              Internship
            </MenuItem>
            <MenuItem
              value={2}
              sx={
                work?.experience_type === 2
                  ? {
                    background: "rgba(0, 52, 187, 0.08)!important",
                    borderRadius: "9px",
                    marginBottom: "5px",
                    fontSize: "14px !important",
                    fontWeight: "400",
                    color: "#0034bb !important",
                    padding: "8px 15px",
                  }
                  : {
                    background: "#fff !important",
                    marginBottom: "5px",
                    fontSize: "14px !important",
                    color: "rgba(33,33,33,.7)",
                    fontWeight: "400",
                    padding: "8px 15px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "9px",
                    },
                  }
              }
            >
              Full Time
            </MenuItem>
          </Select>
        </div>
      </div>

      <div className="row">
        <div className="field">
          <label htmlFor="">Start Date*</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              views={["year", "month"]}
              inputFormat="MM/yyyy"
              disabled={noWorkExp}
              maxDate={
                // work?.experience_status === 3 || work?.experience_status === 1
                //   ? new Date()
                //   : new Date("2030-01-01T00:00:00.000Z")
                new Date()
              }
              minDate={
                // work?.experience_status === 2
                //   ? new Date()
                //   : 
                new Date("2000-01-01T00:00:00.000Z")
              }
              value={work?.start_date ? new Date(work?.start_date) : null}
              onChange={(date) => handleChange("start_date", date)}
              renderInput={(params) => (
                <EcnInput {...params} className="ecn-input" />
              )}
            />
          </LocalizationProvider>
          <FormControlLabel
            className="work-label"
            control={
              <Checkbox
                checked={currentlyWorking}
                onChange={handleCheckboxChange}
                disabled={noWorkExp}
                color="primary"
              />
            }
            label="I currently work here"
          />
        </div>

        <div className="field">
          <label htmlFor="">End Date</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              views={["year", "month"]}
              inputFormat="MM/yyyy"
              disabled={currentlyWorking || noWorkExp}
              maxDate={
                work?.experience_status === 3
                  ? new Date()
                  : new Date("2030-01-01T00:00:00.000Z")
              }
              minDate={new Date(work?.start_date)}
              value={
                currentlyWorking
                  ? null
                  : work?.end_date && work.end_date !== "2030-10-05T14:48:00.000Z"
                    ? new Date(work?.end_date)
                    : null
              }
              onChange={(date) => handleChange("end_date", date)}
              renderInput={(params) => (
                <EcnInput {...params} className="ecn-input" />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="field">
        <label htmlFor="">Job Location*</label>
        <Autocomplete
          forcePopupIcon={false}
          disableClearable
          className="location"
          disabled={noWorkExp}
          options={locationList?.results ?? []}
          value={work?.city}
          onChange={(e, val) => handleChange("city", val)}
          onInputChange={addressListHandler}
          getOptionLabel={(option) =>
            option?.address
              ? `${option.address.municipality}, ${option.address.countrySubdivision}, ${option.address.country}`
              : work?.city
          }
          renderInput={(params) => (
            <EcnInput
              {...params}
              placeholder="Search location"
              size="small"
              className="ecn-input"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Box
              component="li"
              {...props}
              sx={{
                margin: "0px 10px",
                marginBottom: "0px !important",
                borderRadius: "8px !important",
                padding: "10px 15px !important",
                fontSize: "14px !important",
                bgcolor: selected
                  ? "rgba(0, 52, 187, 0.05) !important"
                  : "transparent", // Apply the "info" color for selected options
                "&:hover": {
                  bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                  color: "rgba(0, 52, 187, 1)",
                },
                color:
                  work?.city ===
                    `${option?.address?.municipality}, ${option?.address?.countrySubdivision}, ${option?.address?.country}`
                    ? "rgba(0, 52, 187, 1)"
                    : "",
              }}
            >
              {option?.address?.municipality},{option?.address?.countrySubdivision}
              {option?.address?.countrySubdivision ? "," : ""}
              {option?.address?.country}
            </Box>
          )}
        />
      </div>

      {!isLast && (
        <div className="remove-wrapper">
          <EcnButton
            className="remove-btn"
            color="secondary"
            onClick={() => removeWorkExperience(index)}
          >
            Remove
          </EcnButton>
        </div>
      )}
    </div>
  );
};

export default WorkGroup;

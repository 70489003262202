import React, { useEffect, useState } from 'react'

import { enqueueSnackbar } from "notistack";
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import EcnInput from '../components/StyledComponents/EcnInput';
import EcnButton from '../components/StyledComponents/EcnButton';
import Header from '../components/Nav/Header';

import { checkPrivateJobBoardUrl } from "../redux/slices/jobSlices/checkPrivateJobBoardUrl";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Loader from '../components/UI/Loader';

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const REDIRECTION_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://jobs.dev.ecndev.io"
    : "https://jobs.onefinnet.com";

const EnterJobsDomain = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { error: checkUrlError, loading: checkUrlLoading } = useAppSelector(
    (state) => state.checkPrivateJobBoardUrl,
  );

  const [domainName, setDomainName] = useState("");
  const [showError, setShowError] = useState(false);
  const [clicked, setClicked] = useState(false);

  const baseUrl = window.location.host.replace(/^www\./, '');

  const enterJobDomainHandler = () => {
    dispatch(checkPrivateJobBoardUrl(domainName));
    setClicked(true);
  };

  useEffect(() => {
    if (!checkUrlLoading && clicked) {
      if (domainName == "elevate" || !checkUrlError) {
        setShowError(true);
        if (window.innerWidth > 767) {
          enqueueSnackbar("That doesn't look like a valid Onefinnet URL.", {
            variant: "error",
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            }
          });
        }
        setClicked(false);
      } else {
        setClicked(false);
        navigate(`/${domainName.toLowerCase()}`);
      }
    }
  }, [checkUrlError, checkUrlLoading, clicked]);

  return (
    <div className='enter-jobs-domain'>
      <div className="header">
        <Header />
      </div>
      <div className="page">
        <div className="bg_circle-wrapper">
          <div className="circle circle-one" />
          <div className="circle circle-two" />
          <div className="circle circle-three" />
        </div>

        <div className="page-content">

          <div className="background">
            <div className="navbar">
              <div className="logo">
                <Link to="/">
                  <img src="/b_elevate_2.png" alt="Elevate" />
                </Link>
              </div>
              <Link to={`${REDIRECTION_BASE}/portal/events`} className="home">
                <Icon icon="lucide:home" />
                Home
              </Link>
            </div>

            <div className="main-group">

              <div className="logo-jobs">
                <div className="logo">
                  <Link to="/">
                    <img src="/b_elevate_2.png" alt="Elevate" />
                  </Link>
                </div>
                <div className="jobs">
                  Jobs
                </div>
              </div>

              <div className="text-group">
                <div className="main-text">
                  {window.innerWidth < 768 ? "Enter your job's domain to access your private job board" : "Enter your jobs domain to continue to your private job board"}
                </div>

                <div className="link">
                  <div className="link-text">
                    {baseUrl} /
                  </div>
                  <div className='co-name-input'>
                    <EcnInput placeholder="your-company-name" className="ecn-input job-search"
                      value={domainName}
                      error={showError}
                      onChange={(e) => {
                        setDomainName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          enterJobDomainHandler();
                        }
                      }}
                    />
                    {showError && window.innerWidth < 768 && <p className="error-mssg">
                      <Icon icon="lucide:x" />
                      That doesn't look like a valid Onefinnet URL.
                    </p>}
                  </div>
                </div>
              </div>
              <div className="next-button">
                {checkUrlLoading ?
                  <div className="loader">
                    <Loader />
                  </div>
                  :
                  <EcnButton
                    disabled={checkUrlLoading || domainName.length <= 3}
                    variant="contained" className="btn-contained"
                    onClick={enterJobDomainHandler}
                  >
                    Next
                  </EcnButton>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export default EnterJobsDomain

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { StorageToken } from "./getStorageTokenSlice";

type PayloadType = {
  storageToken: StorageToken;
  fileName: string;
  file: Blob;
};
export const postUserResume = createAsyncThunk(
  "postUserResume",
  async (payload: PayloadType) => {
    try {
      const resourceUrl = `${payload.storageToken.service_url}${payload.storageToken.container_name}/${payload.fileName}`;
      const apiUrl = `${resourceUrl}?${payload.storageToken.token}`;

      await axios.put(apiUrl, payload.file, {
        headers: {
          "Content-Type": "application/pdf",
          "x-ms-blob-type": "BlockBlob",
        },
      });
      return resourceUrl;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data.statusCode;
    }
  },
);

const postUserResumeSlice = createSlice({
  name: "postUserResume",
  initialState: {
    loading: false,
    resumeLink: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postUserResume.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postUserResume.fulfilled, (state, action) => {
      state.loading = false;
      state.resumeLink = action.payload;
    });
    builder.addCase(postUserResume.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default postUserResumeSlice.reducer;

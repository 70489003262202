import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const getStorageToken = createAsyncThunk("getStorageToken", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + AUTH_BASE}/v1/getStorageToken?storage_type=resume`,
    );
    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data.statusCode;
  }
});

export type StorageToken = {
  container_type: string;
  container_name: string;
  service_url: string;
  valid_till: number;
  token: string;
};

type State = {
  loading: boolean;
  storageToken: null | StorageToken;
  error: boolean;
  errorData: null | any;
};

const initialState: State = {
  loading: false,
  storageToken: null,
  error: false,
  errorData: null,
};

const getStorageTokenSlice = createSlice({
  name: "getStorageToken",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStorageToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStorageToken.fulfilled, (state, action) => {
      state.loading = false;
      state.storageToken = action.payload;
    });
    builder.addCase(getStorageToken.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getStorageTokenSlice.reducer;

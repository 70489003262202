import { useState } from "react";
import { ToggleButtonGroup, ToggleButtonGroupProps, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff"
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const EcnToggle = (props: ToggleButtonGroupProps) => {
  const [active, setActive] = useState(props.value);
  const activeHandler = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setActive(newAlignment);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
        width: "fit-content",
      }}
    >
      <StyledToggleButtonGroup
        exclusive
        value={active}
        onChange={activeHandler}
        {...props}
      >
        {props.children}
      </StyledToggleButtonGroup>
    </Paper>
  );
};

export default EcnToggle;

import React from "react";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Stack } from "@mui/material";
import EcnInput from "../components/StyledComponents/EcnInput";
import EcnCheckbox from "../components/StyledComponents/EcnCheckbox";
import EcnChip from "../components/StyledComponents/EcnChip";
import EcnRadio from "../components/StyledComponents/EcnRadio";
import EcnToggle from "../components/StyledComponents/EcnToggle";

import { ToggleButton } from "@mui/material";

const Components = () => {
  return (
    <div style={{ padding: "10px 100px" }}>
      <h1>Buttons</h1>
      <Stack direction="row" spacing={2}>
        <Stack spacing={2} direction="column" alignItems="flex-start">
          <EcnButton variant="outlined" className="btn-outlined">
            Add User
          </EcnButton>
          <EcnButton variant="contained" className="btn-contained">
            Add User
          </EcnButton>
          <EcnButton className="btn-text">Add User</EcnButton>
        </Stack>

        <Stack spacing={2} direction="column" alignItems="flex-start">
          <EcnButton variant="outlined" color="secondary" className="btn-outlined">
            Add User
          </EcnButton>
          <EcnButton variant="contained" color="secondary" className="btn-contained">
            Add User
          </EcnButton>
          <EcnButton color="secondary" className="btn-text">
            Add User
          </EcnButton>
        </Stack>

        <Stack spacing={2} direction="column" alignItems="flex-start">
          <EcnButton variant="outlined" color="info" className="btn-outlined">
            Add User
          </EcnButton>
          <EcnButton variant="contained" color="info" className="btn-contained">
            Add User
          </EcnButton>
          <EcnButton color="info" className="btn-text">
            Add User
          </EcnButton>
        </Stack>
      </Stack>

      <br />
      <h1>Inputs</h1>
      <EcnInput placeholder="Enter Company name" />

      <br />
      <h1>Checkbox</h1>
      <EcnCheckbox />

      <br />
      <h1>Chip</h1>
      <EcnChip label="Basic" variant="outlined" color="secondary" />

      <br />
      <h1>Radio</h1>
      <EcnRadio />

      <br />
      <h1>Toggle</h1>
      <EcnToggle color="primary">
        <ToggleButton value="one" aria-label="check">
          One
        </ToggleButton>
        <ToggleButton value="two" aria-label="check">
          Two
        </ToggleButton>
        <ToggleButton value="three" aria-label="check">
          Three
        </ToggleButton>
      </EcnToggle>
    </div>
  );
};

export default Components;

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getCompanyDetail } from "../redux/slices/jobSlices/getCompanyDetailSlice";

import Header from "../components/Nav/Header";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyJobs } from "../redux/slices/jobSlices/getCompanyJobsSlice";
import EcnInput from "../components/StyledComponents/EcnInput";
import EcnButton from "../components/StyledComponents/EcnButton";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Pagination } from "@mui/material";
import { useQueryParams } from "../helper/HOC/useQueryParams";
import NotFound from "./NotFound";
import Loader from "../components/UI/Loader";

const CompanyJobList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyName } = useParams<{ companyName: string }>();

  const [searchParams, updateQueryParams] = useQueryParams();
  const [search, setSearch] = useState("");

  const { companyDetails, loading } = useAppSelector(
    (state) => state.getCompanyDetails,
  );
  const { jobList } = useAppSelector((state) => state.getCompanyJobs);
  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (companyName) dispatch(getCompanyDetail(companyName));
  }, [companyName]);

  useEffect(() => {
    if (companyName)
      dispatch(
        getCompanyJobs({
          skip: (page - 1) * 6,
          limit: page * 6,
          firm: companyName,
          userId: userInfo?._id,
        }),
      );
  }, [companyName, page, userInfo]);

  useEffect(() => {
    if (!companyName) return;
    const timeoutId = setTimeout(() => {
      dispatch(
        getCompanyJobs({
          skip: 0,
          limit: page * 6,
          firm: companyName,
          q: search,
          userId: userInfo?._id,
        }),
      );
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [search, companyName]);

  const applyHandler = (jobId: string, canNotApply: boolean, closed: boolean) => {
    if (canNotApply || closed) return;
    navigate(`job/${jobId}`);
  };

  const pageHandler = (e: any, page: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    updateQueryParams({ page: page });
    setPage(page);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : companyDetails ? (
        <div className="company-job-list">
          <Header />
          <div className="company-details">
            <div className="container">
              <img
                src={companyDetails?.display_picture}
                alt=""
                className="company-logo"
              />
              <h2>{companyDetails?.title}</h2>
            </div>
          </div>

          <div className="jobs-wrapper">
            <div className="head">
              <h4>Current Openings</h4>
              <EcnInput
                placeholder="Search"
                className="ecn-input job-search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="job-list">
              {jobList?.jobs?.length == 0 &&
                (search !== "" ? (
                  <div className="job-list-fallback">
                    <img
                      src={`https://s1.ecnstatic.com/ecn/images/enterprise/People_searching_for_information_online.svg`}
                      alt="People_searching_for_information_online"
                      style={{ width: 345, height: 345 }}
                    />
                    <div className="fallback-message" style={{ marginTop: 50 }}>
                      No jobs found!
                    </div>
                  </div>
                ) : (
                  <div className="job-list-fallback">
                    <img
                      src={`https://s1.ecnstatic.com/ecn/images/enterprise/No_Jobs_Posted.gif`}
                      alt="No jobs Posted"
                      style={{ width: 400, height: 355 }}
                    />
                    <div className="fallback-message">No jobs posted!</div>
                  </div>
                ))}
              {jobList?.jobs?.map((job: any) => {
                const canNotApply =
                  job?.application &&
                  userInfo &&
                  job.application.created_by._id == userInfo._id;
                return (
                  <div className="job" key={job._id} onClick={() => applyHandler(job?._id, canNotApply, job?.closed)}>
                    <div className="data">
                      <p className="title">{job.job_title}</p>
                      <div className="details">
                        <p>{job.industries?.[0]}</p>
                        <div className="dot" />
                        <p>{job.role_type}</p>
                        <div className="dot" />
                        <p>{job?.address.cities?.[0]}</p>
                      </div>
                      <p className="posted">
                        Posted on {moment.unix(job.createdAt).format("LL")}
                      </p>
                    </div>
                    {job?.closed ? (
                      <EcnButton
                        variant="contained"
                        disabled
                        className="btn-contained apply-btn closed"
                      >
                        Closed
                      </EcnButton>
                    ) :
                      (canNotApply ? (
                        <EcnButton
                          variant="contained"
                          disabled
                          className="btn-contained apply-btn"
                          sx={{ cursor: "not-allowed" }}
                        >
                          Applied
                        </EcnButton>
                      ) : (
                        <EcnButton
                          variant="contained"
                          className="btn-contained apply-btn"
                          endIcon={
                            <Icon
                              icon="lucide:arrow-right"
                              className="apply-icon"
                            />
                          }
                          onClick={() => applyHandler(job?._id, canNotApply, job?.closed)}
                        >
                          Apply
                        </EcnButton>
                      ))}
                  </div>
                );
              })}
            </div>
          </div>

          {jobList?.jobs?.length !== 0 && jobList?.total_count > 6 && (
            <div className="pagination-wrapper">
              <Pagination
                page={page}
                onChange={pageHandler}
                count={
                  jobList?.total_count ? Math.ceil(jobList.total_count / 6) : 1
                }
                color="primary"
                className="job-pagination"
              />
            </div>
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default CompanyJobList;

import React, { useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Components from "./pages/Components";
import CompanyJobList from "./pages/CompanyJobList";
import JobDetail from "./pages/JobDetail";
import Apply from "./pages/Apply";
import { useAppDispatch } from "./redux/hooks";
import { userDetails } from "./redux/slices/userSlices/userDetailsSlice";
import EnterJobsDomain from "./pages/EnterJobsDomain";
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material";
import NotFound from "./pages/NotFound";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#2BBA1D",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },

  "&.notistack-MuiContent-error": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#BA0000",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-warning": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px 23px 16px 21px",
    minWidth: "442px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(254, 152, 0, 1)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-info": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(33, 33, 33, 1)",
      transform: "rotate(180deg)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-default": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    minWidth: "442px",
    fontWeight: "500",
    padding: "12px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
  },
}));

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    // Check if the page has already been reloaded
    if (!sessionStorage.getItem("reloaded")) {
      sessionStorage.setItem("reloaded", "true"); // Mark as reloaded
      window.location.reload(); // Reload the page
    }
  }, []);

  return (
    <Router>
      <SnackbarProvider
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <Icon icon="lucide:check-circle-2" />,
          error: <Icon icon="lucide:x-circle" />,
          warning: <Icon icon="lucide:alert-circle" />,
          info: <Icon icon="lucide:alert-circle" />,
        }}
        action={(snackbarId: any) => (
          <Icon
            onClick={() => closeSnackbar(snackbarId)}
            icon="basil:cross-solid"
            className="snackbar-cross"
          />
        )}
      >
        <Routes>
          <Route path="/" element={<EnterJobsDomain />} />
          <Route path="/--components-template" element={<Components />} />
          <Route path="/:companyName" element={<CompanyJobList />} />
          <Route path="/:companyName/job/:jobId" element={<JobDetail />} />
          <Route path="/:companyName/job/:jobId/apply" element={<Apply />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
}

export default App;

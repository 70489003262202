import getAccountInfoSlice from "./getAccountInfoSlice";
import userDetailsSlice from "./userDetailsSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  getAccountInfo: getAccountInfoSlice,
  userSignIn: userSigninSlice,
  signOut: userSignoutSlice,
};

import React from "react";
import EducationGroup from "./Sub/EducationGroup";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import EcnInput from "../StyledComponents/EcnInput";

const eduTemplate = {
  education_type: 0,
  graduation_year: 0,
  name: "",
  partner_club: "",
};

interface AppData {
  appData: any;
  setAppData: any;
}

const EducationStep = ({ appData, setAppData }: AppData) => {
  const addEducationHandler = () => {
    setAppData({
      ...appData,
      education: [...appData.education, { ...eduTemplate }],
    });
  };

  const changeHandler = (index: number, field: string, value: any) => {
    // Check if the field is either "education_type" or "graduation_year" and convert the value to a number
    const newValue =
      field === "graduation_year"
        ? Number(value)
        : field === "education_type"
        ? value === "Undergrad"
          ? 0
          : 1
        : value;

    // Update the education array with the new value
    const newEducation = appData.education.map((edu: any, i: number) =>
      i === index ? { ...edu, [field]: newValue } : edu
    );

    // Set the updated education array in the appData state
    setAppData({ ...appData, education: newEducation });
  };

  const removeEducationHandler = (index: number) => {
    const newEducation = appData.education.filter(
      (_: any, i: number) => i !== index
    );
    setAppData({ ...appData, education: newEducation });
  };

  const scoreChangeHandler = (e: any) => {
    setAppData({
      ...appData,
      academic_scores: {
        ...appData.academic_scores,
        [e.target.name]: Number(e.target.value),
      },
    });
  };
  return (
    <div className="education-step">
      {appData?.education?.map((edu: any, index: number) => (
        <EducationGroup
          key={index}
          edu={edu}
          index={index}
          onChange={changeHandler}
          onRemove={removeEducationHandler}
          isLast={appData.education.length === 1}
        />
      ))}

      <EcnButton className="add-btn" onClick={addEducationHandler}>
        Add more +
      </EcnButton>

      <div className="heading">Scores</div>
      <div className="row">
        <div className="field">
          <label htmlFor="">GPA*</label>
          <EcnInput
            className="ecn-input"
            name="gpa"
            value={appData.academic_scores.gpa || ""}
            onChange={scoreChangeHandler}
          />
        </div>
        <div className="field">
          <label htmlFor="">SAT</label>
          <EcnInput
            className="ecn-input"
            name="sat"
            value={appData.academic_scores.sat || ""}
            onChange={scoreChangeHandler}
          />
        </div>
      </div>
      <div className="row">
        <div className="field">
          <label htmlFor="">ACT</label>
          <EcnInput
            className="ecn-input"
            name="act"
            value={appData.academic_scores.act || ""}
            onChange={scoreChangeHandler}
          />
        </div>

        <div className="field">
          <label htmlFor="">GMAT</label>
          <EcnInput
            className="ecn-input"
            name="gmat"
            value={appData.academic_scores.gmat || ""}
            onChange={scoreChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default EducationStep;

import React, { useEffect, useState } from "react";
import EcnInput from "../StyledComponents/EcnInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { postUserResume } from "../../redux/slices/jobSlices/postUserResumeSlice";
import PhoneInput from "react-phone-input-2";
import { maskCountry } from "../../helper/mask";
import { getStorageToken } from "../../redux/slices/jobSlices/getStorageTokenSlice";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface AppData {
  appData: any;
  setAppData: any;
  fileName: string;
  setFileName: any;
  mobileError: boolean;
  setMobileError: (value: boolean) => void;
}

const BasicStep = ({ appData, setAppData, fileName, setFileName, mobileError, setMobileError }: AppData) => {
  const dispatch = useAppDispatch();

  const { resumeLink, loading: uploadResumeLoading } = useAppSelector(
    (state) => state.postUserResume,
  );
  const [resumeUploaded, setResumeUploaded] = useState(
    false
  );
  const { storageToken } = useAppSelector((state) => state.getStorageToken);
  const [file, setFile] = useState<Blob>();
  const [uploadClicked, setUploadClicked] = useState(false);

  useEffect(() => {
    if (appData?.resume != null && appData.resume !== "") {
      setResumeUploaded(true);
    }
  }, [appData]);

  useEffect(() => {
    // If storage token is expired in resumeUploadHandler then new action is dispatched to getStorageToken() and file is set
    // else file is undefined and storage token is not changed
    if (
      file &&
      uploadClicked &&
      storageToken &&
      storageToken.valid_till > Date.now() / 1000
    ) {
      dispatch(
        postUserResume({
          storageToken,
          fileName,
          file,
        }),
      );
      setUploadClicked(false);
    }
  }, [storageToken, file]);

  const basicDetailsChangeHandler = (e: any) => {
    setAppData({
      ...appData,
      basic_details: {
        ...appData.basic_details,
        [e.target.name]: e.target.value,
      },
    });
  };

  useEffect(() => {
    setAppData({
      ...appData,
      resume: resumeLink,
    });
    if (resumeLink) {
      setResumeUploaded(true);
    }
  }, [resumeLink]);

  const mobileChangeHandler = (
    val: any,
    country: any,
    e: any,
    formatVal: any,
  ) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setAppData({
      ...appData,
      basic_details: {
        ...appData.basic_details,
        phone: formatVal,
      },
    });
  };

  const resumeUploadHandler = (e: any) => {
    if (e.target.files[0].name == fileName) {
      setResumeUploaded(true);
      return;
    }
    setFileName(e.target.files[0].name);
    if (!storageToken || Date.now() / 1000 > storageToken.valid_till) {
      dispatch(getStorageToken());
      setFile(e.target.files[0]);
      setUploadClicked(true);
    } else {
      dispatch(
        postUserResume({
          storageToken,
          fileName: e.target.files[0].name.replace(/\.[^/.]+$/, "") + "_" + Date.now() + ".pdf",
          file: e.target.files[0],
        }),
      );
    }
  };

  return (
    <div className="basic-step">
      <div className="container">
        <div className="row">
          <div className="field">
            <label htmlFor="">First name*</label>
            <EcnInput
              className="ecn-input"
              name="first_name"
              value={appData.basic_details.first_name}
              onChange={basicDetailsChangeHandler}
            />
          </div>

          <div className="field">
            <label htmlFor="">Last name*</label>
            <EcnInput
              className="ecn-input"
              name="last_name"
              value={appData.basic_details.last_name}
              onChange={basicDetailsChangeHandler}
            />
          </div>
        </div>

        <div className="row">
          <div className="field">
            <label htmlFor="">Email*</label>
            <EcnInput
              className="ecn-input"
              name="email"
              value={appData.basic_details.email}
              onChange={basicDetailsChangeHandler}
            />
          </div>

          <div className="field">
            <label htmlFor="">Phone Number*</label>
            <PhoneInput
              country="us"
              preferredCountries={[
                "gb",
                "fr",
                "es",
                "it",
                "cn",
                "in",
                "us",
                "ca",
              ]}
              containerClass="mobile-input"
              specialLabel=""
              placeholder="Type your mobile number"
              masks={maskCountry}
              isValid={(inputNumber, country, countries) => {
                const valid = countries.some((country: any) => {
                  return inputNumber.startsWith(country.dialCode);
                });
                if (!valid) setMobileError(true);
                return true;
              }}
              value={appData.basic_details.phone}
              onChange={(val, country, e, formatVal) =>
                mobileChangeHandler(val, country, e, formatVal)
              }
            />

            {mobileError && (
              <p className="mobile-error">Invalid Phone Number</p>
            )}
          </div>
        </div>

        <div className="field resume-field">
          <label htmlFor="" className="resume-label">
            Resume *
          </label>
          {resumeUploaded ? (
            <div className="uploaded">
              <div className="file-name-discard">
                <div className="file-name">
                  <Icon
                    icon="vscode-icons:file-type-pdf2"
                    widths={16}
                    height={16}
                    style={{ marginRight: 16 }}
                  />
                  {fileName}
                </div>
                <IconButton
                  className="delete-file-btn"
                  onClick={() => {
                    setResumeUploaded(false);
                    setFile(undefined);
                    setAppData({ ...appData, resume: "" });
                  }}
                >
                  <Icon icon="ic:baseline-close" width={24} height={24} />
                </IconButton>
              </div>
              <div className="uploaded-buttons">
                <Button
                  className="upload-btn view-btn"
                  onClick={() => {
                    window.open(resumeLink || appData.resume, "_blank");
                  }}
                >
                  View
                </Button>
                <LoadingButton
                  loading={uploadResumeLoading}
                  disabled={uploadResumeLoading}
                  component="label"
                  role={undefined}
                  variant="outlined"
                  className="upload-btn"
                  loadingPosition="start"
                  tabIndex={-1}
                  onChange={(e) => {
                    // If file is undefined and storage token is changed then file will not be uploaded
                    setFile(undefined);
                    resumeUploadHandler(e);
                  }}
                >
                  Replace
                  <VisuallyHiddenInput type="file" accept=".pdf, .docx" />
                </LoadingButton>
              </div>
            </div>
          ) : (
            <LoadingButton
              loading={uploadResumeLoading}
              disabled={uploadResumeLoading}
              component="label"
              role={undefined}
              variant="outlined"
              className="upload-btn"
              tabIndex={-1}
              loadingPosition="start"
              onChange={resumeUploadHandler}
            >
              Upload file
              <VisuallyHiddenInput type="file" accept=".pdf, .docx" />
            </LoadingButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicStep;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

import React, { useState } from "react";
import WorkGroup from "./Sub/WorkGroup";
import EcnButton from "../StyledComponents/EcnButton";
import { Checkbox, FormControlLabel } from "@mui/material";

interface AppData {
  appData: any;
  setAppData: any;
  noWorkExp: any;
  setNoWorkExp: any;
}

const workTemplate = {
  city: "",
  company: "",
  end_date: "2030-10-05T14:48:00.000Z",
  experience_status: 0,
  experience_type: 0,
  industry: "",
  position: "",
  start_date: "",
  title: "",
  zip_code: "",
  logo: "",
  description: "",
};

const WorkStep = ({ appData, setAppData, noWorkExp, setNoWorkExp }: AppData) => {
  const noWorkExpHandler = (e: any) => {
    setNoWorkExp(e.target.checked);
    if (e.target.checked === true) {
      setAppData({ ...appData, work_experiences: [] });
    } else {
      setAppData({ ...appData, work_experiences: [{ ...workTemplate }] });
    }
  }; //setNoWorkExp handler
  const addWorkExperience = () => {
    const newWork = { ...workTemplate };
    const updatedWorkExperiences = [...appData.work_experiences, newWork];
    setAppData({ ...appData, work_experiences: updatedWorkExperiences });
  };

  const updateWorkExperience = (index: number, updatedWork: any) => {
    const updatedWorkExperiences = appData.work_experiences.map(
      (work: any, i: number) => (i === index ? { ...work, ...updatedWork } : work)
    );
    setAppData({ ...appData, work_experiences: updatedWorkExperiences });
  };

  const removeWorkExperience = (index: number) => {
    const updatedWorkExperiences = appData.work_experiences.filter(
      (work: any, i: number) => i !== index
    );
    setAppData({ ...appData, work_experiences: updatedWorkExperiences });
  };




  return (
    <div className="work-step">
      <FormControlLabel
        className="no-work-label"
        control={
          <Checkbox
            checked={noWorkExp}
            onChange={noWorkExpHandler}
            color="primary"
          />
        }
        label="I do not have relevant work experience"
      />
      {appData?.work_experiences?.map((work: any, index: number) => (
        <WorkGroup
          key={index}
          work={work}
          index={index}
          noWorkExp={noWorkExp}
          updateWorkExperience={updateWorkExperience}
          removeWorkExperience={removeWorkExperience}
          isLast={appData.work_experiences.length === 1}
        />
      ))}

      {noWorkExp && (
        <WorkGroup
          work={workTemplate}
          index={0}
          noWorkExp={noWorkExp}
          updateWorkExperience={updateWorkExperience}
          removeWorkExperience={removeWorkExperience}
          isLast={true}
        />
      )}

      <EcnButton
        className="work-add-btn"
        disabled={noWorkExp}
        onClick={addWorkExperience}
      >
        Add more +
      </EcnButton>
    </div>
  );
};

export default WorkStep;

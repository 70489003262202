import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Icon } from "@iconify/react";
import EcnInput from "../StyledComponents/EcnInput";
import { LinkedinShareButton, WhatsappShareButton } from "react-share";

const ShareJobDialog = ({
  open,
  setOpen,
  link,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  link: string;
}) => {
  const [copied, setCopied] = useState(false);
  const copyLinkHandler = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    });
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      className="share-job-dialog"
    >
      <DialogTitle className="dialog-title">
        Share
        <div className="dialog-description">
          Share the jobs across the platforms
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
        }}
      >
        <Icon icon="lucide:x" width={24} height={24} />
      </IconButton>
      <DialogContent className="dialog-content">
        <div className="job-link-container">
          <div className="link-label">Link</div>
          <EcnInput
            disabled
            value={link}
            className={`link-input ${copied ? "copied-input" : ""}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={copyLinkHandler}>
                    <Icon icon="lucide:copy" width={20} height={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {copied && (
            <div className="copied-text">Link copied successfully</div>
          )}
        </div>
        <div className="or-label">or share with</div>
        <div className="social-icon-container">
          <LinkedinShareButton
            url={link}
            className="social-icon-button"
            style={{
              border: "1px solid rgba(33, 33, 33, 0.2)",
              width: 44,
              height: 44,
              borderRadius: 6,
              padding: 10,
            }}
          >
            <Icon icon="ri:linkedin-fill" width={22} height={22} />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={link}
            className="social-icon-button"
            style={{
              border: "1px solid rgba(33, 33, 33, 0.2)",
              width: 44,
              height: 44,
              borderRadius: 6,
              padding: 10,
            }}
          >
            <Icon icon="ic:outline-whatsapp" width={22} height={22} />
          </WhatsappShareButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ShareJobDialog;

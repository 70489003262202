import React from "react";
import { Chip, ChipProps } from "@mui/material";

const EcnChip = (props: ChipProps) => {
  
  return (
    <Chip
      {...props}
      className={props.className ? props.className + " ecn-chip" : "ecn-chip"}
    />
  );
};

export default EcnChip;
